.input-container {
  display: inline;
  .input {
    width: 470px;
    height: 60px;
    margin-top: 10px;
    border: none;
    border-radius: 3px;
    outline: none;
    padding: 5px 10px;
  }

  .input-search {
    background-color: $colorTransparent;
    width: 100%;
    outline: 0;
    border: solid;
    border-radius: 0;
    border-width: 0 0 1px;
    border-color: $grayColor;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}
