.user-page {
  .containers {
    display: flex;
    justify-content: space-between;
  }

  h1,
  h2,
  p,
  span {
    color: $headerColor;
  }

  .signature {
    font-size: 18px;
    font-style: oblique;
  }

  .first-container,
  .second-container,
  .third-container {
    width: 26%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .container-bottom {
    display: flex;
    margin-top: 20px;

    div {
      width: 30%;
      margin: auto;

      h2 {
        text-align: center;
        font-size: 18px;
      }

      span {
        display: block;
        text-align: center;
        padding: 5px 0;
      }
    }
  }

  .give-admin-rights {
    background-color: #458eff !important;

    span {
      color: white !important;
      text-transform: initial !important;
    }
  }
  .featured{
    background-color: #387e7b !important;

    span {
      color: white !important;
      text-transform: initial !important;
    }
  }

  .first-container {
    width: 45%;

    hr {
      margin: 0;
    }

    .container-top {
      display: flex;

      .user-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
        width: 120px;
        height: 120px;

        .avatar-badge {
          background-color: #1554F6;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: 100;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: #ffffff !important;
          }
        }

        .stars {
          display: block;
          width: 100%;
          margin-top: 1em;
          text-align: center;
        }

        .block-button {
          width: 125px;
          margin-top: 10px;
        }
      }

      .user-info {
        width: 100%;
        margin: 0 0 20px 25px;

        .user-top {
          margin-bottom: 20px;

          .role {
            float: right;
            line-height: 35px;
          }

          h2 {
            cursor: pointer;
            line-height: 40px;
            display: inline;
            font-size: 24px;
          }
        }

        .authenticated-label {
          color: #1554F6;
          cursor: pointer;
        }

        p {
          font-weight: 600;
          display: inline-block;
          width: 100%;
          line-height: 30px;
          margin-bottom: 5px;

          span {
            font-weight: 400;
            float: right;
          }
        }
      }
    }
  }

  .second-container,
  .third-container {

    .bottom-data {
      margin: auto 0;
    }
  }
  .earnings-buttons{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 30px;

    .button{
      width: 215px;
      margin-bottom: 15px;
      padding: 0;
    }
  }

  .earnings-form{
    height: 75px;
    .error-label {
      font-size: 13px;
      color: red;
    }
  }

  .verification-id-container img{
    width: 100%;
  }
}
