.info-card{
    position: relative;
}
.bio-counter {
  margin-left: 92%;
}
.edit-input {
  width: 100%;
  border: none;
  outline: none;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 96%;
  .btn {
    border: none;
    background: none;
    font-size: 18px;
  }
}
