.pagination {
  flex-direction: column;
}
.paginationWrapper {
  padding: 2em 0;
  display: flex;
  justify-content: center;

  .separator {
    width: 1em;
    margin: 0 0.25em;
  }
  .pageItem {
    background: transparent;
    border: none;
    height: 2em;
    width: 2em;
    margin: 0 0.25em;
    border-radius: 50%;
    font-weight: 600;
    color: $grayColor;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: 0;
    }

    &.active {
      background-color: $grayColor;
      color: $whiteColor;
    }

    &.sides {
      box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
        rgba(0, 0, 0, 0.18) 0px 2px 4px;
      &:disabled {
        background-color: #cccccc;
        opacity: 0.5;
        color: $grayColor;
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }
      &:hover {
        text-decoration: none;
        box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
          rgba(0, 0, 0, 0.12) 0px 6px 16px;
      }
    }
  }
}
