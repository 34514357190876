.vat-container {
  height: 100%;
  background-color: #ffffff;

  .MuiTablePagination-toolbar {
    display: none !important;
  }

 

  .vat-form {

    >div {
      margin: 10px 0;
    }
  }
}
.add-field {
  position: fixed;
  bottom: 30px;
  right: 5%;
  width: 80px;
  height: 80px;
  background-color: #FF766B;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  svg {
    font-size: 36px;
  }

  &:active {
    transition: 0.1s ease-in-out;
    transform: scale(0.9);
  }

  &:hover {
    background-color: #FD4E4E;
  }
}