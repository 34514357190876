.system-meeting-link-container{
    display: flex;
    background-color: white;
    padding: 5px;
}

.system-meeting-link {
    margin: 0 25px 0 25px;
}

.false {
    color: gray
}

.system-meeting-link-button {
    color: blue;
    width: 85px;
}

.system-meeting-link-button:hover {
    text-decoration: none;

    label:hover{
        cursor: pointer;
    }
}