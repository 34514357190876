.chusp-container {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;

  .spark {
    width: 5px;
    height: 5px;
    background-color: orangered;
    border-radius: 50%;
    box-shadow: 0 0 5px 5px red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .s1 {
    animation: fadein 5s ease infinite, floatup 5s ease infinite,
    left 5s ease infinite;
  }
  .s2 {
    animation: fadein 5s 0.5s ease infinite, floatup 5s 0.5s ease infinite,
    right 5s 0.5s ease infinite;
  }
  .s3 {
    animation: fadein 5s 1s ease infinite, floatup 5s 1s ease infinite,
    farleft 5s 1s ease infinite;
  }
  .s4 {
    animation: fadein 5s 1.5s ease infinite, floatup 5s 1.5s ease infinite,
    farright 5s 1.5s ease infinite;
  }
  .s5 {
    animation: fadein 5s 2s linear infinite, floatup 5s 2s linear infinite;
  }
  .s6 {
    animation: fadein 5s 2.5s ease infinite, floatup 5s 2.5s ease infinite,
    left 5s 2.5s ease infinite;
  }
  .s7 {
    animation: fadein 5s 3s ease infinite, floatup 5s 3s ease infinite,
    right 5s 3s ease infinite;
  }
  .s8 {
    animation: fadein 5s 3.5s ease infinite, floatup 5s 3.5s ease infinite,
    farleft 5s 3.5s ease infinite;
  }
  .s9 {
    animation: fadein 5s 4s ease infinite, floatup 5s 3.5s ease infinite,
    farleft 5s 3.5s ease infinite;
  }
  .s10 {
    animation: fadein 5s 4.5s ease infinite, floatup 5s 3.5s ease infinite,
    farleft 5s 3.5s ease infinite;
  }
  .s11 {
    animation: fadein 5s 5s ease infinite, floatup 5s 3.5s ease infinite,
    farleft 5s 3.5s ease infinite;
  }
  .s12 {
    animation: fadein 5s 5.5s ease infinite, floatup 5s 3.5s ease infinite,
    farleft 5s 3.5s ease infinite;
  }

  @keyframes flicker {
    0%,
    100% {
      background-color: red;
      box-shadow: -10px -10px 0 10px orange, -15px -15px 0 15px yellow;
    }
    25%,
    75% {
      background-color: red;
      box-shadow: -10px -10px 0 10px orangered, -20px -20px 0 20px yellow;
    }
    50% {
      background-color: red;
      box-shadow: -10px -10px 0 10px orange, -15px -15px 0 15px yellow;
    }
  }

  @keyframes fadein {
    0%,
    100% {
      opacity: 0;
    }
    75% {
      opacity: 100%;
    }
  }

  @keyframes floatup {
    0% {
      top: 40%;
    }
    100% {
      top: 0;
    }
  }
  @keyframes left {
    0% {
      left: 50%;
    }
    100% {
      left: 55%;
    }
  }
  @keyframes right {
    0% {
      left: 50%;
    }
    100% {
      left: 60%;
    }
  }
  @keyframes farleft {
    0% {
      left: 50%;
    }
    100% {
      left: 40%;
    }
  }
  @keyframes farright {
    0% {
      left: 50%;
    }
    100% {
      left: 30%;
    }
  }
  @keyframes background {
    0%,
    50%,
    100% {
      box-shadow: 0 0 50px 50px #880e4f;
    }
    25%,
    75% {
      box-shadow: 0 0 60px 60px #880e4f;
    }
  }

  .chusp-wrapper {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);


    .chusp-loader {
      position: relative;
      height: 200px;
      width: 200px;
      display: block;
      background-color: #121212;
      border-radius: 50%;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        transform: scale(1.3);
        z-index: 100;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: -3px;
      top: -3px;
      background: linear-gradient(45deg, #A10100, #DA1F05, #F33C04, #FE650D, #A10100, #DA1F05, #A10100, #F33C04, #FE650D);
      background-size: 400%;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      z-index: -1;
      border-radius: 50%;
      animation: glower 20s linear infinite;
    }

    &::after {
      content: "";
      position: absolute;
      top: -43%;
      left: -5%;
      background-color: #121212;
    }

    @keyframes glower {
      0% {
        background-position: 0 0;
      }

      50% {
        background-position: 400% 0;
      }

      100% {
        background-position: 0 0;
      }
    }

  }
}
