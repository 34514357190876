.error-info-container {
  pre {
    width: 600px;
    max-width: max-content;
    max-height: max-content;
    white-space: pre-wrap;
    background-color: #000000;
    color: #ffffff;
    padding: 15px;
  }

  .info {
    min-width: 600px;
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 15px;
    height: fit-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);


    p {
      white-space: pre-wrap;
      margin-bottom: 0 !important;
    }
  }

  .action-btn {
    min-width: 600px;
    max-width: max-content;
    background-color: $chuspBlue;
    color: #ffffff;

    &:hover {
      background-color: $chuspBlue;
      opacity: 0.75;
    }
  }
}