.card-table-container{
    table{
        border-collapse: collapse;
        width: 230px;

        th, td{
            border: 1px solid black;
            padding: 5px;
        }
    }
}