.template-page {
  .create-dynamic-template {
    display: flex;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }
    .checkboxes {
      display: flex;
      .check-box-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .list-container {
    margin-top: 15px;
  }
  .no-version-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    p {
      color: grey;
      margin-bottom: 1%;
    }
  }
  .total-count {
    text-align: center;
    font-family: "Poppins Light";
    font-size: 21px;
    font-weight: bold;
    color: #858585;

    span {
      font-weight: bold;
      color: red;
    }
  }
  .no-template-found p {
    display: flex;
    justify-content: center;
    color: grey;
    font-size: 18px;
  }
}
.back-btn-div {
  .back-btn {
    border: none;
    color: rgb(64, 93, 230);
    margin-bottom: 2%;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-top: -2%;
    background: transparent;
    // &:hover {
    //   background-color: #1554f6;
    //   color: white;
    // }
  }
  .email-service-btn{
    margin-top: 0%;
  }
  .smtp-btn{
    margin-bottom: 0px;
  }
}
.file-selected {
  color: rgb(119, 119, 119);
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.btn-add-template {
  color: white;
  background-color: #1554f6;
  width: 10%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  label {
    margin-bottom: 0px;
  }
  label:hover {
    cursor: pointer;
}
}

.image-name-remove-btn{
  display: flex;
    // justify-content: space-between;
    width: 75%;
    // margin-top: -5px;
    .MuiSvgIcon-root{
      font-size: 1.1rem;
      // margin-top: 8px;
      &:hover{
        cursor: pointer;
      }
    }
}
.image-name-remove-btn-mob{
  display: flex;
  // justify-content: space-between;
  align-items: baseline;
  width: 100%;
  .MuiSvgIcon-root{
    font-size: 1.1rem;
    // margin-top: 8px;
    &:hover{
      cursor: pointer;
    }
  }
}