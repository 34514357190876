.add-email-form {
    display: flex;
    flex-direction: column;


    .form-row {
        margin: 10px;

        label {
            padding: 5px;
            margin-bottom: 0;
            width: 30%;
        }

        input {
            padding: 6px;
            font-size: 14px;
            width: 70%;
        }
    }
}

.add-email-button {
    margin-top: 15px !important;
}

.close-modal-button {
    color: blue;
    font-weight: 500;
    padding: 0 15px 10px 0;
    cursor: pointer;
    font-size: 22px;
}