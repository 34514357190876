.email-notification-type {
    
    .email-notification-type-heading {
        margin-bottom: 30px;
        text-align: center;
    }
    
    .email-notification-type-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: white;
        width: fit-content;
        margin: auto;
    }
}