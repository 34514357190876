.option-with-icon-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  button {
    height: 100px;
    width: 100px;
    border: 1px solid #AAAAAA;

    span {
      width: 100%;
      height: 100%;

      svg.MuiSvgIcon-root {
        width: 60%;
        height: 100%;
      }
    }

  }

  p {
    width: 100px;
    text-align: center;
    font-size: 16px;
    color: black;
  }

}