.card-container{
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    width: min-content;
    padding: 20px;
    box-shadow: 1px 6px 5px rgb(194, 194, 194);
    margin: 10px;

    .card-title{
        color: rgb(116, 116, 116);
        font-size: 15px;
        white-space: nowrap;
    }
}