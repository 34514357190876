.edit {
  max-width: fit-content;
  height: 70vh;
  background-color: white;
  margin: 0 auto;
  padding: 2% 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;

  h2 {
    text-align: center;
    color: grey;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .edit__top {
    display: flex;
    align-items: center;
    flex-direction: column;

    .edit__top-avatar {
      width: 80px;
      height: 80px;
      font-size: 34px;
      background-color: $chuspBlue;
    }


    span {
      font-size: 18px;
      color: grey;
      font-weight: 400;
    }
  }

  .edit__main {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 100%;

    .edit__main-email, .edit__main-password {
      border-bottom: 1px solid lightgrey;

      &:focus-within {
        border-bottom: 2px solid $chuspBlue;
      }
    }

    button {
      margin-top: auto;
      width: 100%;
      background-color: $chuspBlue;
      color: #ffffff;
      height: 36px;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: bold;
      -webkit-box-shadow: 0 27px 54px 0 rgba(41, 25, 25, 0.2);
      -moz-box-shadow: 0 27px 54px 0 rgba(41, 25, 25, 0.2);
      box-shadow: 0 27px 54px 0 rgba(41, 25, 25, 0.2);
      transition: 0.15s ease-in-out;

      &:hover {
        background-color: #1D3EAF;
        transition: 0.15s ease-in-out;
      }

      &:active {
        background-color: $chuspBlue;
        transform: scale(0.97);
        transition: 0.15s ease-in-out;
      }

      &:disabled {
        opacity: 0.6;
        background-color: $chuspBlue;
      }
    }


  }
}
