.request-info {
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;

  .user {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user_avatar {
      width: 120px;
      height: 120px;
    }

    .user_additional_info {
      flex: 0.5;
      width: 100%;

      p {
        color: grey;
        width: 100%;
      }

      span, a {
        width: 100%;
        display: inline-block;
        color: black;
        font-weight: normal;
        margin-left: auto;
      }

      a {
        color: #1554F6;
      }
    }
  }

  .passport-id {
    h2 {
      opacity: 0.8;
      font-size: 20px;
      margin-top: 5%;
    }

    img {
      width: 200px;
      border-radius: 6px;
      cursor: pointer;
      margin-top: 3%;
    }

    .blurred {
      filter: blur(8px);
    }
  }

  .decision {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10%;

    button {
      border: none;
      padding: 5px 15px;
      flex: 0.3;
      color: #ffffff;
      font-weight: bold;
      border-radius: 6px;
      transition: 0.1s ease-in-out;

      &:hover {
        transition: 0.1s ease-in-out;
        opacity: 0.7;
      }
    }

    .decision_accept {
      background-color: #4CAF50;
    }

    .decision_retake {
      background-color: #405DE6;
    }

    .decision_reject {
      background-color: #FF766B;
    }
  }
}

.passport_full_screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 30%;
    animation: initialPreviewAnimation 0.3s ease-in-out;
  }

  @keyframes initialPreviewAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.7)
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1)
    }
  }
}