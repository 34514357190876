$whiteColor: #ffffff;
$blackColor: #000000;
$grayColor: #555555;
$lightGray: #DDDDDD;
$redColor: #ff0000;
$carrotRed: #FF766B;
$chuspBlue: #405DE6;
$forestGreen: #228b22;
$mintGreen: #65E5BF;
$blueColor: #0000ff;
$darkWhite: #fbfbfb;
$fontGray: #3d5170;
$background: #F2F4F7;
$headerColor: #6A6D7C;
$colorTransparent: rgba(0, 0, 0, 0);
$lightBlue: rgb(93, 206, 240);
$violet: #a759cf;

