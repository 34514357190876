.faq-container {
  width: 55%;
  margin: 0 auto;
  background-color: white;
  padding: 60px;
  height: max-content;

  .ql-editor {
    height: 200px;
  }

  @media (max-width: 1500px) {
    width: 90%;
  }

  .add-new-topic {
    padding: 5px 15px;
    background-color: $chuspBlue;
    color: #ffffff;
    border-radius: 6px;
    transition: 0.2s ease-in-out;
    text-transform: initial;
    width: max-content;

    &:hover {
      background-color: $chuspBlue;
      opacity: 0.7;
    }
  }

  .faq-form {
    display: flex;
    flex-direction: column;

    .ql-editor.ql-blank::before {
      font-style: initial;
      color: lightgray;
      font-size: 16px;
    }

    .create-mode {
      animation: opacity 0.3s ease-in-out;
      margin-top: 15px;
    }

    .save-btn {
      text-transform: initial;
      background-color: $chuspBlue;
      color: #ffffff;
      transition: 0.2s ease-in-out;

      &:hover {
        opacity: 0.7;
        background-color: $chuspBlue;
      }

      &:disabled {
        background-color: grey;
      }
    }
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .faq {
    width: 100%;
    animation: opacity 0.3s ease-in-out;

    .question-edit-btns {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 5px;

      button {
        width: fit-content;
        background-color: lightgray;
        border: 1px solid white;
        opacity: 0.7;
        text-transform: initial;
        transition: 0.2s ease-in-out;
        color: #ffffff;
        margin: 0 5px;

        &:nth-child(1) {
          background-color: transparent;
          border: 2px solid dimgray;
          color: dimgray;

          &:hover {
            background-color: dimgray;
            color: #ffffff;
          }
        }

        &:nth-child(2) {
          background-color: $redColor;
          border: 2px solid transparent;

          &:hover {
            opacity: 0.5;
            background-color: $redColor;
          }
        }
      }
    }

    .action-question-btn {
      width: 100%;
      background-color: $chuspBlue;
      color: #ffffff;
      text-transform: initial;
      transition: 0.2s ease-in-out;

      &:hover {
        opacity: 0.7;
        background-color: $chuspBlue;
      }

      &:disabled {
        background-color: grey;
      }
    }

    .faq-header {
      display: flex;
      align-items: center;

      .action-btns {
        margin-left: auto;

        button {
          text-transform: initial;
          margin: 0 5px;

          &:nth-child(1) {
            background-color: transparent;
            border: 1px solid dimgray;
            color: dimgray;

            &:hover {
              background-color: dimgray;
              color: #ffffff;
              border: 1px solid #ffffff;
            }
          }

          &:nth-child(2) {
            background-color: transparent;
            border: 1px solid dimgray;
            color: dimgray;

            &:hover {
              background-color: dimgray;
              color: #ffffff;
              border: 1px solid #ffffff;
            }
          }

          &:nth-child(3) {
            background-color: $redColor;
            color: #ffffff;
            opacity: 0.7;

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }

      .save-topic-btn {
        text-transform: initial;
        font-weight: bold;
        color: #6C6C6C;
        border: 1px solid lightgray;
        border-bottom: none !important;

        &:hover {
          opacity: 0.7;
        }

        &:disabled {
          background-color: transparent;
          color: transparent;
          border: none;
        }
      }

      input {
        margin-top: 10px;
        height: 40px;
        font-size: 18px;
        width: fit-content;
        font-weight: 600;
        text-decoration: underline;
        color: #0f172a;
        opacity: 0.9;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    h3 {
      font-size: 18px;
      margin-top: 20px;
      text-decoration: underline;
      color: #0f172a;
      opacity: 0.9;

      &:first-letter {
        //text-transform: capitalize;
      }
    }

    #panel1a-header {
      border-top: 1px solid lightgray;
      background-color: #F2F2F2;
    }

    .accordion-details {
      background-color: #E0E0E0;
    }

    .question {
      word-wrap: break-word;

      &:first-letter {
        //text-transform: capitalize;
      }
    }

    .answer {
      background-color: #e0e0e0 !important;
      word-wrap: break-word;

      p {
        margin: 0;
      }
    }

    .accordion {
      display: grid;
      grid-template-columns: 2fr 0.2fr;
      align-items: start;
      animation: opacity 0.3s ease-in-out;
      margin-bottom: 20px;

      .ql-editor ol, ul {
        //FOR BULLET POINTS REACT QUIL
        padding-left: 0 !important;
      }
    }
  }


  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.ql-snow .ql-tooltip {
  z-index: 9999;
}