.wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  animation: modalAnimation 0.3s ease-in-out;
  @keyframes modalAnimation {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1;
    }
  }
}

.active {
  display: block;
}

.details-modal {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgba(black, 0.2);
  left: 50%;
  max-width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  animation: scaleAnimation .5s ease-in-out;

.icon-container{
  width: 100%;
  text-align: center;
  .success-icon{
    color: #4CAF50;
    font-size: 100px !important;
    position: absolute;
    left: 40%;
    top: -40px;
    background-color: white;
    border-radius: 50%;
    animation: fall-in .5s ease-in-out;
    @keyframes fall-in{
      0% { transform: scale(3); opacity: 0}
      50%  { transform: scale(1); opacity: 1}
      60% {  transform: scale(1.1);}
      100% {  transform: scale(1);}
    }
  }
  .error-icon{
    color: #FC0101;
    font-size: 100px !important;
    position: absolute;
    left: 40%;
    top: -40px;
    background-color: white;
    border-radius: 50%;
    animation: fall-in .5s ease-in-out;
    @keyframes fall-in{
      0% { transform: scale(3); opacity: 0}
      50%  { transform: scale(1); opacity: 1}
      60% {  transform: scale(1.1);}
      100% {  transform: scale(1);}
    }
  }
}
  @keyframes scaleAnimation {
    0% {opacity: 0; }
    100% {opacity: 1; }
  }
  // -------------- CLOSE

  .details-modal-close {
    align-items: center;
    color: #111827;
    display: flex;
    height: 4.5em;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 4.5em;

    svg {
      display: block;
    }
  }

  // -------------- TITLE

  .icon-modal-title {
    color: #111827;
    padding: 1.5em 2em;
    pointer-events: all;
    position: relative;
    // width: calc(100% - 4.5em);
    width: 100%;
    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: normal;
    }
  }

  // -------------- CONTENT

  .details-modal-content {
    border-top: 1px solid #e0e0e0;
    padding: 2em;
    pointer-events: all;
    overflow: auto;
    p{
      font-size: 18px;
    }
  }
  .confirm-button{
    width: 100%;
    text-align: right;
    z-index: 9999;
    button{
      border: none;
      border-radius: 5px;
      color: black;
      font-weight: 600;
      font-size: 20px;
      background-color: transparent;
      padding: 5px;
      margin: 5px;
      z-index: 1500;

    }
  }
}

// -------------- DETAILS

details {
  summary {
    list-style: none;

    &:focus {
      outline: none;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }
}
