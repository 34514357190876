.header-container {
  height: 70px;
  width: 100%;
  background-color: $whiteColor;
  box-shadow: 1px 2px 7px -1px rgb(194, 194, 194);
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 10px 25px;

  .burger-menu {
    cursor: pointer;
  }

  .profile-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;

    .chusp-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      opacity: 0.7;
      cursor: pointer;
    }

    .profile-image {
      width: 50px;
      height: 50px;
      cursor: pointer;
      background-color: $chuspBlue;
    }

    .logout-button {
      text-transform: capitalize !important;
    }
  }
}
