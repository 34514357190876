.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;

  .col {
    flex-basis: 0;
    flex-grow: 1;
    margin: 0 auto;

    .card {
      position: relative;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: $whiteColor;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25em;
    }

    .card-small {
      box-shadow: 0 2px 0 RGB(90 97 105 / 11%), 0 4px 8px RGB(90 97 105 / 12%),
        0 10px 10px RGB(90 97 105 / 6%), 0 7px 70px RGB(90 97 105 / 10%);
    }

    .mb-4,
    .my-4 {
      margin-bottom: 1.5em !important;
    }

    table {
      border-collapse: collapse;

      .link {
        cursor: pointer;
        font-style: italic;
        color: $chuspBlue;
        font-size: 18px;
      }

      .admin-user,
      .host-user,
      .verified-host-user,
      .visitor-user {
        border: none;
      }


      .email {
        font-style: italic;
      }

      .tr-pointer{
        cursor: pointer;
      }
      .tr-pointer:hover {
        box-shadow: inset 1px 0 0 #dadce0, inset 1px 0 0 #dadce0,
          inset 0 1px 2px 0 RGB(60 64 67 / 30%),
          inset 0 1px 3px 1px RGB(60 64 67 / 15%);
        z-index: 2;
        background-color: #f5f5f5;
      }
    }
  }
}
