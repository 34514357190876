.login-container {
  height: 100vh;
  width: 100vw;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .create-pass-form,
  .signin-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: rgba(43, 43, 43, 0.9);
    width: 450px;
    height: 400px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    transition: 0.5s;

    h1 {
      position: relative;
      text-align: center;
      color: #ffffff;
      transition: 0.5s;
      padding-bottom: 50px;
      visibility: hidden;
    }

    input {
      width: 350px !important;
      height: 46px !important;
      border: none;
      background-color: #424242 !important;
      color: white !important;
      border-radius: 10px !important;
      -webkit-box-shadow: -1px 6px 9px -3px rgba(39, 32, 32, 0.24);
      -moz-box-shadow: -1px 6px 9px -3px rgba(39, 32, 32, 0.24);
      box-shadow: -1px 6px 9px -3px rgba(39, 32, 32, 0.24) !important;
    }

    .pass-wrapper,
    .email-wrapper {
      position: relative;
      display: flex;
      margin-bottom: 14px;
    }

    .show-hide {
      cursor: pointer;
      position: absolute;
      right: 1%;
      top: 55%;
      transform: translateY(-50%);
    }

    .error-label {
      color: red;
    }

    button {
      background-color: #AD1104;
      color: #ffffff;
      width: 30%;
      height: 36px;
      border: none;
      border-radius: 10px;
      padding: 10px;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: bold;
      margin-top: 10px;
      -webkit-box-shadow: 0 27px 54px 0 rgba(41, 25, 25, 0.2);
      -moz-box-shadow: 0 27px 54px 0 rgba(41, 25, 25, 0.2);
      box-shadow: 0 27px 54px 0 rgba(41, 25, 25, 0.2);

      &:hover {
        background-color: #DA1F05;
      }
    }
  }

  .create-pass-form {
    button {
      width: 50%;
    }
  }
}

