.system-meetings-page {
    margin: 50px 0 50px 0;
}

.generate-meeting-link-button {
    width: 175px;
}

.generate-meeting-link-button:hover {
    cursor: pointer;
}

.close-modal-button {
    color: blue;
    font-weight: 500;
    padding: 0 15px 10px 0;
    cursor: pointer;
}