.complaint-details-page {
  h1,
  h2,
  p,
  span {
    color: $headerColor;
  }

  .complaint-page-buttons {
    margin-top: 20px;

    .green-button,
    .red-button,
    .blue-button {
      width: 200px;
      height: 50px;
      line-height: 50px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
  }

  .green-button,
  .red-button,
  .blue-button {
    float: right;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
  }

  .green-button:hover,
  .red-button:hover,
  .blue-button:hover {
    opacity: 0.7
  }


  .info-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70vh;

    @media(max-width: 1400px) {
      flex-direction: column;
      height: auto;
    }


    .complaint-event-info {
      background-color: #ffffff;
      height: 100%;
      padding: 20px;
      flex: 0.28;
      margin-bottom: 3%;

      .info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        margin: 30px 0;
      }

      h2 {
        font-size: 18px;
      }

      @media(max-width: 1400px) {
        position: relative;
        top: 30px;
      }
    }
  }

  .complaints {
    display: flex;
    justify-content: space-between;
    flex: 0.7;

    svg {
      margin: auto;
      font-size: 36px;
      opacity: 0.5;
    }

    .user-info {
      flex: 0.49;
      height: 100%;
      padding: 20px;
      background: #ffffff;

      .no-complaints {
        margin-top: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-style: italic;
          opacity: 0.5;
        }
      }

      h2 {
        font-size: 18px;
      }
    }
  }
}

@keyframes initialOpacityAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}
