.corporate-accounts-page{


  .corporate-accounts-card{
    background-color: #ffffff;
    width: 850px;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    position: relative;



    .balance {
      display: flex;
      flex-direction: column;

      p{
        .corporate-accounts-loader-dbs {
          position: absolute;
          left: 50%;
          top: 40%;
          transform: translate(-50%, -50%);
          color: #FD4E4E;
        }

        .corporate-accounts-loader-tw {
          position: absolute;
          left: 50%;
          top: 75%;
          transform: translate(-50%, -50%);
          color: #FD4E4E;
        }
      }
    }
    h2 {
      font-size: 24px;
      margin-bottom: 30px;
    }
    h5{
      font-size: 18px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        margin: 15px 0;
      }
    }
  }
}