.ticket-author {
  display: flex;
}

.ticket_details {
  display: flex;

  .ticket-assets {
    width: 30%;
    height: fit-content;
    background-color: #ffffff;
    padding: 30px 20px;

    .reported-posts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .reported-img {
        position: relative;
        width: 120px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        button {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: red;
          color: #ffffff;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          box-shadow: 10px 10px 94px -21px rgba(0,0,0,0.75);
          -webkit-box-shadow: 10px 10px 94px -21px rgba(0,0,0,0.75);
          -moz-box-shadow: 10px 10px 94px -21px rgba(0,0,0,0.75);
          transition: 0.1s ease-in-out;

          &:hover {
            transform: scale(1.1);
            transition: 0.1s ease-in-out;
          }
        }
      }
    }

    .img-wrapper {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: rgba(0, 0, 0, 0.7);

      img {
        max-height: 90vh;
        width: 900px;
        left: 0;
        object-fit: contain;
        bottom: 0;
        margin: auto;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        -o-object-fit: contain;
        z-index: 1;
        animation: initialOpacityAnimation 0.4s ease-in-out;
        border-radius: 6px;
      }
    }

    img {
      cursor: pointer;

    }

    h2 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
}
.ticket-info {
  width: 30%;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 30px 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-shadow: 10px -1px 53px -21px rgba(197,197,197,0.51);
  -webkit-box-shadow: 10px -1px 53px -21px rgba(197,197,197,0.51);
  -moz-box-shadow: 10px -1px 53px -21px rgba(197,197,197,0.51);

  h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 5px 15px;
      border: none;
      margin-left: 10px;
      color: #ffffff;
      border-radius: 6px;
      &:nth-child(2) {
        background-color: $lightBlue;
      }

      &:active {
        transform: scale(0.97);
      }
    }
  }

  .ticket-action {
    border: none;
    padding: 5px 25px;
    display: flex;
    margin-left: auto;
    margin-top: 20px;
    color: #ffffff;
    background-color: $chuspBlue;
    border-radius: 6px;

    &:active {
      transform: scale(0.97);
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

@keyframes initialOpacityAnimation {
  0% {opacity: 0; transform: scale(0.7)}
  100% {opacity: 1; transform: scale(1)}
}
