.email-container-page {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .email-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;

    .email-container {
      height: 100%;
      width: 100%;

      .email-input {
        width: 100%;
        background-color: white;
      }
      .email-body {
        width: 100%;
        background-color: white;

        .ql-editor {
          min-height: 350px;
          max-height: 40vh;
        }
      }
      .disable-color {
        opacity: 0.5;
      }

      .email-receivers {
        width: 100%;
        margin: 15px 0;
      }
    }

    .templates {
      h2 {
        font-size: 18px;
        opacity: 0.7;
      }

      .template-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .MuiGrid-item {
          display: flex;
          flex-direction: column;
          flex-grow: initial;
          img {
            width: 150px;
            height: 100%;
            border-radius: 6px;
            margin-right: 1%;
            cursor: pointer;
            height: 150px;
            object-fit: fill;
            background: white;
          }
        }

        .MuiGrid-spacing-xs-2 {
          width: auto !important;
        }
      }
    }
  }

  .upload-container {
    width: 50%;
    flex-flow: column;
    align-items: center;
    .upload-btn {
      .span-container {
        margin-left: 11px;
        .supported-file {
          font-size: 12px;
          color: grey;
        }
      }
    }
    .upload {
      margin-left: 10px;
      height: 3.8em;
    }

    span {
      font-weight: 500;
    }

    img {
      margin-top: 110px;
      margin-left: 20px;
      height: 100%;
      object-fit: contain;
    }
  }
}
.image-container {
  width: 150px;
  height: 100%;
  border-radius: 6px;
  margin-right: 1%;
  cursor: pointer;
  object-fit: fill;
  background: #fff;
  overflow: hidden;
  min-height: 150px;
  max-height: 150px;
}
.template-heading {
  font-size: 18px;
    opacity: .7;
}
.templates .template-container .MuiGrid-item {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 200px;
  height: 200px;
}
.email-body {
  width: 100%;
  background-color: white;
}
.ql-editor {
  min-height: 350px;
  max-height: 40vh;
}
.disable-color {
  opacity: 0.5;
}
.subject-input{
  p{
    margin: 1px 14px 8px 0px;
  }
}
.clickable_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 1500px;
  background-color: transparent; // semi-transparent background
  z-index: 1,
}