.analytics-page {
  display: flex;


  .analytic-inputs {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .countries {
      width: 75%;
      margin-right: 20px;
      background-color: #ffffff;
    }

    .date {
      background-color: #ffffff;
    }
    .css-1a1ihhj{
      width: 0!important;
    }
  }

  .analytics-loader {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    color: #FD4E4E;
  }

  .analytics-card {
    background-color: #ffffff;
    width: 850px;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    position: relative;

    .spending, .earnings {
      display: flex;
      flex-direction: column;
    }

    h2 {
      font-size: 24px;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        margin: 15px 0;
      }
    }
  }
  .left-card{
    margin-right: 20px;
  }
}
