.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $background;
  }

  .page {
    margin: 35px;
  }
}

.top-panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5em;
  justify-content: center;
  margin: 0 15px;
  width: 100%;
}

.admin-user,
.host-user,
.verified-host-user,
.visitor-user {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-user {
  border: 3px solid $redColor;
  color: $redColor !important;
}

.host-user {
  border: 3px solid $forestGreen;
  color: $forestGreen !important;
}

.visitor-user {
  border: 3px solid $blueColor;
  color: $blueColor !important;
}
.verified-host-user {
  border: 3px solid $redColor;
  color: $redColor !important;
}
.info-card {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  float: left;
  min-height: 200px;
  background-color: $whiteColor;
  padding: 25px 15px;
  border-radius: 10px;

  h2 {
    // text-align: center;
    font-size: 18px;
  }

  .main-data {
    h2 {
      display: inline-block;
      font-size: 18px;
    }

    span {
      float: right;
    }
  }
}
.violet-button,
.green-button,
.red-button,
.blue-button{
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: $whiteColor !important;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.98);
  }
}
.green-button{
  background-color: $mintGreen;
}

.red-button{
  background-color: $carrotRed;
}

.blue-button{
  background-color: $lightBlue;
}
.violet-button{
  background-color: $violet;
}

.card-signature {
  font-size: 18px;
  font-style: oblique;
}
