.system-meetings-to-user-page {
    display: flex;
    justify-content: left;
    margin: 5px;

    .system-meeting-container-left {
        .system-meeting-container-left-top {
            display: flex;
            justify-content: left;
    
            .user-container {
                background-color: white;
                margin: 15px;
                padding: 10px;
            }
    
            .timestamp-container {
                background-color: white;
                margin: 15px;
                padding: 10px;
                
                .timestamp-container-data {
                    display: flex;
                }
            }
        }
    
        .system-meeting-container-left-bottom {

            .environment-container {
                    background-color: white;
                    margin: 15px;
                    padding: 10px;
                
                .environment-container-data {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    .system-meeting-container-right {

        .connection-container {
            background-color: white;
            margin: 15px;
            padding: 10px;

            .connection-container-data {
                display: flex;
                justify-content: space-between;

                .boolean-true {
                    font-weight: 500;
                    color: #228b22;
                }
                
                .boolean-false {
                    font-weight: 500;
                    color: red;
                }

                .error-true {
                    font-weight: 500;
                    color: gray;
                }

                .error-false {
                    font-weight: 500;
                    color: red;
                }
            }
        }
    }

    .system-meeting-data-heading {
        font-weight: bold;
        color: gray;
        padding-bottom: 10px;
    }
    
    .system-meeting-data-subheading {
        color: gray;
        font-weight: 500;
    }

    .system-meeting-key-value {
        display: flex;
        justify-content: space-between;
        
        .system-meeting-key {
            color: gray;
            padding: 5px;
        }

        .system-meeting-value {
            text-align: right;
            padding: 5px;
        }
    }

    .user {
        padding: 5px;
    }
}


button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.go-back-button.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    color: gray;
    border-color: gray;
}