.main-sidebar {
  height: 100%;
  background-color: $whiteColor;
  display: flex;
  flex-direction: column;
  z-index: 1070;
  box-shadow: 0 0.125em 9.375em RGB(90 97 105 / 10%),
    0 0.25em 4em 15px RGB(90 97 105 / 12%),
    0 0.9375em 1.375em RGB(90 97 105 / 10%),
    0 0.4375em 2.1875em RGB(165 182 201 / 10%);
  width: 17%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-100%);
  transition: 0.4s;
  overflow: auto;

  .close-btn {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: $grayColor;
  }

  .main-logo {
    padding: 0.5em;
    background-color: $darkWhite;
  }

  .full-logo {
    display: block;
    width: 150px;

  }

  .sidebar-icon {
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    color: #cacedb;
    transition: color 0.2s ease;
    margin-right: 0.5em;
  }

  .sidebar-options {
    text-decoration: none;
    border-bottom: 1px solid #e1e5eb;
    padding: 0.9375em 1.5625em;
    color: $blackColor;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    span {
      &:hover {
        transform: scale(2);
      }
    }
  }

  .selected,
  .sidebar-options:hover {
    font-weight: bold;
    box-shadow: inset 0.1875em 0 0 $redColor;
    background-color: $darkWhite;
    color: $redColor;
  }
}

.activated {
  transform: translateX(0);
}
