.users-page {
  .nth-found {
    margin: 2em auto;
    text-align: center;
    opacity: 0.7;
    font-size: 24px;
  }
  .total-count{
    text-align: center;
    font-family: $poppinsLightFont;
    font-size: 21px;
    font-weight: bold;
    color: #858585;

    span{
      font-weight: bold;
      color: red;
    }
  }
}

.input-wrapper {
  position: relative;
  margin-bottom: 14px;

  .clear-input {
    cursor: pointer;
    position: absolute;
    right: 1.5%;
    top: 55%;
    transform: translateY(-50%);
  }
}
