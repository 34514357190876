* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll !important;
}
.details-container {
  position: fixed;
  left: 0; right: 0; top: 70px; bottom: 0;
  background-color: #F2F4F7;
  z-index: 999;
  overflow-y: auto;
  padding: 35px;
  padding-top: 5px;
}
.go-back-button{
  margin-bottom: 10px !important;
}

.nth-found {
  margin: 2em auto;
  text-align: center;
  opacity: 0.7;
  font-size: 24px;
}
//variables
@import "./variables/color.scss";
@import "./variables/fonts.scss";

//components
@import "../components/ChuspAnimated/chusp.scss";
@import "../components/Card/card.scss";
@import "../components/Chart/chart.scss";
@import "../components/Card-table/card-table.scss";
@import "../components/Input/input.scss";
@import "../components/Pagination/pagination.scss";
@import "../components/Table/table.scss";
@import "../components/Select/select.scss";
@import "../components/Modal/modal.scss";
@import "../components/Modal/SuccessOrErrorModal/successOrErrorModal.scss";
@import "../components/option-with-icon-and-text/option-with-icon-and-text.scss";


//containers

@import "../containers/Sidebar/sidebar.scss";
@import "../containers/Header/header.scss";

//pages
@import "../pages/Overview/overview";
@import '../pages/MainContainer/main-container.scss';
@import "../pages/Login/login.scss";
@import "../pages/Meetings/meetings.scss";
@import "../pages/Tickets/Reports/reports.scss";
@import "../pages/Tickets/TicketInfo/ticket-info";
@import "../pages/Analytics/analytics.scss";
@import "../pages/Users/index.scss";
@import "../pages/User/index.scss";
@import "../pages/SystemVariable/system_variable";
@import "../pages/Complaints/complaint.scss";
@import "../pages/ComplaintDetails/components/complaint-details";
@import "../pages/EditProfile/edit";
@import "../pages/VAT/vat";
@import "../pages/CorporateAccounts/corporate-accounts";
@import "../pages/EmailService/email-service";
@import "../pages/VerificationRequests/verification";
@import "../pages/FAQ/faq";
@import "../pages/SystemErrors/error-info-page";
@import "../pages/EmailNotificationManagement/email-notification-management";
@import "../pages/EmailNotificationManagement/components/requests-email-list.scss";
@import "../pages/DBSPayout/index.scss";
@import "../pages/EditUserProfile/edit-profile.scss";
@import "../pages/SMTP/smtp.scss";
@import "../pages/TemplateEditor/template.scss";
@import "../pages/TemplateEditor/preview.scss";
.sun-editor {
  height: 100% !important;
}
.sun-editor .se-wrapper {
  height: 100% !important;
}
.sun-editor .se-wrapper .se-wrapper-code {
  height: inherit !important;
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  height: auto !important;
}
.CodeMirror {
  // color: white !important;
  // background: black !important;
  height: 1000px !important;
  // height: 800px !important;
}
// .CodeMirror-cursor {
//   border-left: 1px solid white  !important;
// }
// .cm-tag { color: #358EE4; } /* HTML tags */
// .cm-property { color: #358EE4; } 
// .cm-attribute { color: #358EE4;}
// .cm-string {color: #E3CC64;}

iframe {
  border: none !important;
}
.CodeMirror-vscrollbar {
  width: 8px !important;
}
.normal-container {
  width: 500px; /* Or any other default width */
  height: 300px; /* Or any other default height */
  border: 1px solid black;
}
iframe#desktop_iframe {
  height: 700px !important;
  width: 1440px !important;
}

iframe#mobile_iframe{
  height: 600px !important;
}
@media screen and (min-width: 1900px) {
  .container {
    width: 1870px;
  }
  .CodeMirror {
    // color: white !important;
    // background: black !important;
    height: 1000px !important;
    // height: 800px !important;
  }
  // iframe#desktop_iframe {
  //   height: 900px !important;
  // }
  
  // iframe#mobile_iframe{
  //   height: 600px !important;
  // }
}
.MuiButtonBase-root.MuiChip-root.MuiChip-sizeSmall.MuiChip-clickable.MuiChip-deletable {
//   /* justify-content: space-between; */
//   margin-left: 11px;
//   height: 30px;
  margin: 0 8px 8px 0;
}
.receiver-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}
.MuiChip-root {
 height: 32px !important;
}

span.MuiChip-label.MuiChip-labelSmall {
  overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
}
div#email-container .MuiInputBase-fullWidth {
  // width: 100%;
  background-color: white;
}
