.button {
    color: white;
    background-color: #1554F6;
    padding: 5px 5px 5px 7px;
    border-radius: 5px;

    label:hover {
        cursor: pointer;
    }
}

