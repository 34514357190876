.data-grid-container {
  height: 85vh;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 0 RGB(90 97 105 / 11%), 0 4px 8px RGB(90 97 105 / 12%),
  0 10px 10px RGB(90 97 105 / 6%), 0 7px 70px RGB(90 97 105 / 10%);

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
