.overview {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;

  .overview__content {
    background-color: white;
    border-radius: 10px;
    width: 80vw;
    height: fit-content;
    padding: 1em 5em;


    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      grid-gap: 25px;

      .overview__card:hover .icon {
        background-color: #ffffff;
        color: $chuspBlue;
        -webkit-box-shadow: 0 0 8px -25px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0 0 8px -25px rgba(34, 60, 80, 0.2);
        box-shadow: 0 0 8px -25px rgba(34, 60, 80, 0.2);
      }

      .overview__card:hover .info {
        color: #ffffff;
      }

      .overview__card {
        //width: 400px;
        height: 120px;
        border: 1px solid lightgray;
        border-radius: 6px;
        margin: 10px 0;
        -webkit-box-shadow: 0 0 6px 0 rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0 0 6px 0 rgba(34, 60, 80, 0.2);
        box-shadow: 0 0 6px 0 rgba(34, 60, 80, 0.2);
        display: flex;
        align-items: center;
        padding: 0.5em 10px;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
          background-color: $chuspBlue;
          transition: 0.2s ease-in-out;
        }

        .icon {
          width: 75px;
          height: 75px;
          background-color: $chuspBlue;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 34px;
          }
        }

        .info {
          margin-left: 20px;

          h3 {
            font-size: 22px;
            line-height: 20px;
          }

          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}