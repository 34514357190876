.wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  animation: modalAnimation 0.3s ease-in-out;
  @keyframes modalAnimation {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1;
    }
  }
}

.active {
  display: block;
}

.details-modal {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgba(black, 0.2);
  left: 50%;
  max-width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32em;
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  animation: scaleAnimation .4s ease-in-out;

  @keyframes scaleAnimation {
    0% {opacity: 0; transform: scale(0.7)}
    100% {opacity: 1; transform: scale(1)}
  }
  // -------------- CLOSE

  .details-modal-close {
    align-items: center;
    color: #111827;
    display: flex;
    height: 4.5em;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 4.5em;

    svg {
      display: block;
    }
  }

  // -------------- TITLE

  .details-modal-title {
    color: #111827;
    padding: 1em 2em;
    pointer-events: all;
    position: relative;
    // width: calc(100% - 4.5em);
    text-align: center;
    width: 100%;
    h1 {
      font-size: 18px !important;
      font-weight: 600;
      line-height: normal;
      vertical-align: middle;
      margin: auto;
      width: 50%;
    }
  }

  .details-modal-subtitle {
    color: #111827;
    padding: 0 2em;
    pointer-events: all;
    position: relative;
    width: 100%;
    h2 {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  // -------------- CONTENT

  .details-modal-content {
    pointer-events: all;
    padding: 1em 2em !important;
    overflow: auto;
    div {
      font-size: 16px;
    }
  }
  .modal-actions{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em 1.2em;
    z-index: 9999;
    button {
      cursor: pointer;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      font-size: 20px;
      background-color: transparent;
      padding: 5px;
      margin: 5px;
      z-index: 1500;

      &:hover {
        opacity: 0.8;
      }
    }
    .cancel {
       color: #6A6D7C !important;
    }
    .confirm {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #1554F6 !important;
        &:disabled {
          color: lightgrey !important;
        }
    }
  }
}

// -------------- OVERLAY

.details-modal-overlay {
  transition: opacity 0.2s ease-out;
  pointer-events: none;
  background: rgba(#0f172a, 0.8);
  position: fixed;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  details[open] & {
    pointer-events: all;
    opacity: 0.5;
  }
}

// -------------- DETAILS

details {
  summary {
    list-style: none;

    &:focus {
      outline: none;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }
}

// -------------- OTHER

code {
  font-family: Monaco, monospace;
  line-height: 100%;
  background-color: #2d2d2c;
  padding: 0.1em 0.4em;
  letter-spacing: -0.05em;
  word-break: normal;
  border-radius: 7px;
  color: white;
  font-weight: normal;
  font-size: 1.75rem;
  position: relative;
  top: -2px;
}

.container {
  text-align: center;
  max-width: 40em;
  padding: 2em;

  > h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: normal;
    color: #111827;
  }

  > p {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  sup {
    font-size: 1rem;
    margin-left: 0.25em;
    opacity: 0.5;
    position: relative;
  }
  @media screen and (min-width: 1200px) {
    div#Preview .details-modal-content{
      overflow: hidden !important;

    }
    // .details-modal .details-modal-content div{
    //   height: 900px !important;
    // }

  //   iframe#desktop_iframe {
  //   height: 900px !important;
  // width: 1440px;
  // overflow: hidden;
  // }
  
  // iframe#mobile_iframe{
  //   height: 600px !important;
  // }
}
}
